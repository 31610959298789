import { Container } from "react-bootstrap";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import React from "react";
import Title from "../components/Title";

const Traveltips = () => {
  return (
    <div>
      <Navbar />
      <Header currentpage="Travel Tips" title="Travel Tips" />
      <Title title="TRAVEL TIPS" />
      <div className="traveltips">
        <Container>
          <p>
            TO MAKE YOUR HOLIDAY / TOUR MEMORABLE, ENJOYABLE AND SAFE, YOU HAVE
            TO BE PREPARED YOURSELF AS WELL AS SOME IMPORTANT THINGS AS UNDER:
          </p>
          <ul>
            <li>Collect all information about Place, rout & sightseeing</li>
            <li>
              Plan your Holidays with your Friends OR Relatives instead of
              individual
            </li>
            <li>Discuss about your holiday destination with you family</li>
            <li>Book your Travel Tickets and Hotel booking well in advance</li>
            <li>
              Avoid unnecessary things while going on holiday. Carry only
              Necessary things like daily wear Cloths, Cosmetics, Torch, etc
            </li>
            <li>Pleae carry original Identity Proof of all family members</li>
            <li>
              Consume healthy and hygienic food and purified water during
              Holiday
            </li>
            <li>
              Pack your baggage with appropriate cloths according to climate of
              Holiday Destination. e.g. If you are visiting Kashmir or other
              Hill Stations, keep some woolen cloths
            </li>
            <li>
              Keep friendly behavior with local people when you are on Holiday
              to avoid unnecessary disputes
            </li>
            <li>
              Take Travel Insurance for your health and luggage to secure your
              family
            </li>
            <li>Take one guide when you are going for sightseeing</li>
            <li>Do not keep much cash with you, Use ATM as your requirement</li>
            <li>
              Check your health with doctor before going on holiday and do not
              forget to keep First Aid Kit with necessary Medicines
            </li>
            <li>Carry At least One Cell Phone to contact in an emergency</li>
            <li>
              Give One copy of your Holiday schedule to your relative or
              neighbour for an emergency
            </li>
            <li>Keep CAMERA to make your holiday MEMORABLE</li>
          </ul>
        </Container>
      </div>
      <br />
      <Footer />
    </div>
  );
};

export default Traveltips;
