import "./App.css";
//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";
//core
import "primereact/resources/primereact.min.css";

import { BrowserRouter, Route, Routes } from "react-router-dom";

import Aboutus from "./pages/Aboutus";
import Bookingrequest from "./pages/Bookingrequest";
import Bulkholidays from "./pages/Bulkholidays";
import Contactus from "./pages/Contactus";
import { Helmet } from "react-helmet";
import Home from "./pages/Home";
import Hotellist from "./pages/Hotellist";
import Offseasonchart from "./pages/Offseasonchart";
import Traveltips from "./pages/Traveltips";

function App() {
  return (
    <div>
      <Helmet>
        <script
          data-cfasync="false"
          src="../../cdn-cgi/scripts/5c5dd728/cloudflare-static/email-decode.min.js"
        ></script>
        <script src="assets/js/jquery-3.5.1.slim.min.js"></script>

        <script src="assets/js/popper.min.js"></script>

        <script src="assets/js/bootstrap.min.js"></script>

        <script src="assets/js/jquery.meanmenu.js"></script>

        <script src="assets/js/wow.min.js"></script>

        <script src="assets/js/owl.carousel.js"></script>

        <script src="assets/js/jquery.magnific-popup.min.js"></script>

        <script src="assets/js/jquery.nice-select.min.js"></script>

        <script src="assets/js/parallax.min.js"></script>

        <script src="assets/js/jquery.mixitup.min.js"></script>

        <script src="assets/js/jquery.appear.js"></script>

        <script src="assets/js/odometer.min.js"></script>

        <script src="assets/js/jquery.ajaxchimp.min.js"></script>

        <script src="assets/js/form-validator.min.js"></script>

        <script src="assets/js/contact-form-script.js"></script>

        <script src="assets/js/custom.js"></script>
      </Helmet>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="home" element={<Home />} />
          <Route path="about" element={<Aboutus />} />
          <Route path="contact" element={<Contactus />} />
          <Route path="travel-tips" element={<Traveltips />} />
          <Route path="off-season-chart" element={<Offseasonchart />} />
          <Route path="hotel-lists" element={<Hotellist />} />
          <Route path="bulk-holidays-packages" element={<Bulkholidays />} />
          <Route path="booking-request" element={<Bookingrequest />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
