import { Col, Container, Row } from "react-bootstrap";
import React, { useState } from "react";

import Button from "react-bootstrap/Button";
import Footer from "../components/Footer";
import Form from "react-bootstrap/Form";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import Title from "../components/Title";

const Bookingrequest = () => {
  const [isValid, setIsValid] = useState(false);
  const ValidateCoupon = () => {
    setIsValid(true);
  };

  const CancelValidate = () => {
    setIsValid(false);
  };
  return (
    <div>
      <Navbar />
      <Header currentpage="Booking Request" title="Hotel Booking Request" />
      <Title title="Hotel Booking Request" />
      <Container>
        <div>
          <Row xs="auto">
            <Col sm={4} style={{ borderRight: "1px solid red" }}>
              <h5>HOTEL BOOKING REQUEST - HOW TO SUBMIT REQUEST?</h5>
              <ul>
                <div style={{ fontSize: "14px" }}>
                  <li>
                    <strong>
                      Make Payment in Given Account and Upload Your Payment Slip{" "}
                    </strong>
                    <br />
                    Bank Name : AXIS BANK LTD <br />
                    IFSC Code : UTIB0003117
                    <br />
                    Branch Name : PAL, Surat <br />
                    Account No : 918020086402343
                  </li>
                  <li>
                    Enter Your Valid Coupon Number and press Validate To Get
                    Booking Access
                  </li>
                  <li>Mark Fields are Mandantory</li>
                  <li>
                    Read All Terms and Condition Carefully before You Submit
                    Hotel Booking Request
                  </li>
                </div>
              </ul>
            </Col>
            <Col sm={8}>
              <Form>
                <Form.Group className="mb-3" controlId="formBasicText">
                  <Form.Label>Enter Coupon Pin Number</Form.Label>
                  <Form.Control placeholder="Enter Coupon Number" />
                </Form.Group>

                <Button variant="primary" onClick={ValidateCoupon}>
                  Validate Coupon Number
                </Button>
                {isValid && (
                  <div style={{ padding: "10px" }}>
                    <Form.Group className="mb-3" controlId="formBasicText">
                      <Form.Label>Your Full Name :</Form.Label>
                      <Form.Control placeholder="Enter Your Name" />
                    </Form.Group>

                    <Button variant="primary" onClick={CancelValidate}>
                      Cancel
                    </Button>
                  </div>
                )}
              </Form>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default Bookingrequest;
