import { Column } from "primereact/column";
import { Container } from "react-bootstrap";
import { DataTable } from "primereact/datatable";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import React from "react";
import Title from "../components/Title";

const HotelData = [
  {
    state: "JAMMU AND KASHMIR",
    cityname: "KATRA",
    hotelname: "HOTEL ATUL REGENCY",
    website: "HTTP://WWW.HOTELATULREGENCY.COM",
  },
  {
    state: "HIMACHAL PRADESH",
    cityname: "DHARAMSHALA",
    hotelname: "HOTEL TRIUND",
    website: "HTTP://WWW.HOTELTRIUND.COM",
  },
];

const Hotellist = () => {
  return (
    <div>
      <Navbar />
      <Header currentpage="Off Season Chart" title="Off Season Chart" />
      <Title title="Off Season Chart" />
      <Container>
        <DataTable value={HotelData} style={{ fontSize: "13px" }}>
          <Column
            field="state"
            header="State Name"
            sortable={true}
            style={{ width: "300px" }}
          />
          <Column
            field="cityname"
            header="Location"
            sortable={true}
            style={{ width: "100px" }}
          />
          <Column
            field="hotelname"
            header="Hotel Name"
            sortable={true}
            style={{ width: "300px" }}
          />
          <Column
            field="website"
            header="Website"
            sortable={true}
            style={{ width: "300px" }}
          />
        </DataTable>
      </Container>
      <br />
      <Footer />
    </div>
  );
};

export default Hotellist;
