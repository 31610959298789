import { Col, Container, Row } from "react-bootstrap";

import Footer from "../components/Footer";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import React from "react";
import Title from "../components/Title";

const Aboutus = () => {
  return (
    <div>
      <Navbar />
      <Header currentpage="About" title="About us" />
      <Title title="About us" />
      <Container>
        <Row>
          <Col sm={6}>
            <img
              width="100%"
              src="https://blog.erasmusgeneration.org/sites/default/files/articles/2022-03/travelling_alone_all_across_the_globe.jpg"
              alt="About us"
            />
            <img
              width="100%"
              src="https://img.freepik.com/free-photo/selective-focus-miniature-tourist-compass-map-with-plastic-toy-airplane-abstract-background-travel-concept_1423-180.jpg"
              alt="About us"
            />
          </Col>
          <Col sm={6}>
            <p>
              We take the pleasure to introduce ourselves as one of the leading
              firms in the travel industry providing impeccable service on time
              every time to our valued customers in the corporate sector as well
              as individuals.
            </p>
            <p>
              Real Holidays, Surat has been providing travel services to its
              clients all over India since the last 15 yrs. Be it air ticketing,
              Railway e-ticketing, hotel bookings, bulk holiday packages ( For
              Corporate Clients), Real Holidays provides a One Stop Shop for all
              your travel and holiday requirements.
            </p>
            <p>
              The company is mainly into Corporate Bulk Holiday Bookings
              providing its clients with value addition to their products and
              satisfying the end user by letting them enjoy their holidays at a
              very reasonable price. The said bulk holiday package can be
              combined with any existing company product and in return the
              product gets a value addition which might be in fact much more
              than the price of the original product.
            </p>
            <p>
              The concept has been widely accepted by our reputed clients as
              well as their principles in Surat and other cities across India.
            </p>
            <p>
              At present Real Holidays has entered into collaboration with 45
              hotels all over India for providing its services. More hotels are
              being added to the list as per the company policy.
            </p>
            <p>
              The market worth of the said packages is approx. Rs.2000/- to
              Rs.6000/- depending on the nights offered. In addition we can also
              prepare a customized holiday package as per the client's choice.
            </p>
            <p>
              The holiday packages are valid during the off season periods of
              the year.
            </p>
            <p>
              We at Real Holidays make sure that each and every customer of ours
              is satisfied with our services as well as the services of the
              respective hotels booked thru us.
            </p>
            <p>
              Real Holidays is committed to providing the best of services on
              time to all its customers with the sole aim of having 100 %
              Customer Satisfaction.
            </p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};

export default Aboutus;
