import About from "../components/About";
import AwesomeDestination from "../components/AwesomeDestination";
import Fade from "react-reveal/Zoom";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import NavbarBS from "../components/NavbarBS";
import React from "react";
import Slider from "../components/Slider";

const Home = () => {
  return (
    <div>
      <Navbar />
      <Slider />
      <Fade>
        <About />
      </Fade>
      <Fade>
        <AwesomeDestination />
      </Fade>
      <Fade>
        <Footer />
      </Fade>
    </div>
  );
};

export default Home;
