import React from "react";

const Title = (props) => {
  return (
    <div className="one" style={{ padding: "15px", marginTop: "10px" }}>
      <h1>{props.title}</h1>
    </div>
  );
};

export default Title;
