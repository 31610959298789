import { Col, Row } from "react-bootstrap";

import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import React from "react";
import Title from "./Title";

const About = () => {
  return (
    <div>
      <Container fluid>
        <Title title="About us" />
        <Row>
          <Col sm="6">
            <img
              src="assets/img/about.jpg"
              alt=""
              className="img-fluid"
              style={{ borderRadius: "10px", boxShadow: "10px 10px 5px #ccc" }}
            />
          </Col>
          <Col sm="6">
            <p>
              We take the pleasure to introduce ourselves as one of the leading
              firms in the travel industry providing impeccable service on time
              every time to our valued customers in the corporate sector as well
              as individuals.
            </p>
            <p>
              Real Holidays, Surat has been providing travel services to its
              clients all over India since the last 15 yrs. Be it air ticketing,
              Railway e-ticketing, hotel bookings, bulk holiday packages ( For
              Corporate Clients), Real Holidays provides a One Stop Shop for all
              your travel and holiday requirements.
            </p>
            <p>
              The company is mainly into Corporate Bulk Holiday Bookings
              providing its clients with value addition to their products and
              satisfying the end user by letting them enjoy their holidays at a
              very reasonable price. The said bulk holiday package can be
              combined with any existing company product and in return the
              product gets a value addition which might be in fact much more
              than the price of the original product.
            </p>
            <Link to="/about" className="active">
              More...
            </Link>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default About;
