import { Col, Container, Row } from "react-bootstrap";

import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import React from "react";
import Title from "./Title";

const AwesomeDestination = () => {
  return (
    <div>
      <Container fluid>
        <Title title="Awesome Destinations" />
        <Row xs="auto">
          <Col sm={3}>
            <Card style={{ width: "18rem" }}>
              <Card.Img
                variant="top"
                src="https://hindi.nativeplanet.com/img/2016/10/800px-sarovar-and-the-golden-temple-21-1477051297.jpg"
              />
              <Card.Body>
                <Card.Title>
                  <strong>स्वर्ण मंदिर</strong>
                </Card.Title>
                <Card.Text>
                  अमृतसर का स्वर्ण मंदिर भारत के सबसे प्रसिद्द मंदिरों में से एक
                  है जहाँ रोज़ भक्तों का लाखों की संख्या में हुजूम उमड़ता है।
                </Card.Text>
                <Link to="https://en.wikipedia.org/wiki/Golden_Temple">
                  Know More
                </Link>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={3}>
            <Card style={{ width: "18rem" }}>
              <Card.Img
                variant="top"
                src="https://jhakas.com/wp-content/uploads/2020/04/Taj-mahal-in-hindi.jpg"
              />
              <Card.Body>
                <Card.Title>
                  <strong>ताजमहल</strong>
                </Card.Title>
                <Card.Text>
                  ताजमहल भारतीय शहर आगरा में यमुना नदी के दक्षिण तट पर एक
                  हाथीदांत-सफेद संगमरमर का मकबरा है।
                </Card.Text>
                <Link to="https://www.tajmahal.gov.in/">Know More</Link>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={3}>
            <Card style={{ width: "18rem" }}>
              <Card.Img
                variant="top"
                src="https://navbharattimes.indiatimes.com/thumb/83229081/things-not-to-do-in-goa-in-hindi-83229081.jpg?imgsize=181670&width=540&height=405&resizemode=75"
              />
              <Card.Body>
                <Card.Title>
                  <strong>गोवा</strong>
                </Card.Title>
                <Card.Text>
                  गोवा के प्राचीन इतिहास का आरम्भ तीसरी सदी इसा पूर्व से शुरु
                  होती है जब यहाँ मौर्य वंश के शासन की स्थापना हुई थी।
                </Card.Text>
                <Link to="https://www.goa.gov.in/">Know More</Link>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={3}>
            <Card style={{ width: "18rem" }}>
              <Card.Img
                variant="top"
                src="https://www.financialexpress.com/wp-content/uploads/2021/09/Kerala-Tourism.jpg"
              />
              <Card.Body>
                <Card.Title>
                  <strong>केरल</strong>
                </Card.Title>
                <Card.Text>
                  समुद्र और पर्वत के संगम स्थान को भी केरल कहा जाता है। प्राचीन
                  विदेशी यायावरों ने इस स्थल को 'मलबार' नाम से भी सम्बोधित किया
                  है।
                </Card.Text>
                <Link to="https://en.wikipedia.org/wiki/Kerala">Know More</Link>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <br></br>
    </div>
  );
};

export default AwesomeDestination;
