import { Link } from "react-router-dom";
import React from "react";

const Header = (props) => {
  return (
    <section
      id="breadcrumbs"
      className="breadcrumbs"
      style={{ marginTop: "100px" }}
    >
      <div className="container">
        <div className="d-flex justify-content-between align-items-center">
          <h2>{props.title}</h2>
          <ol>
            <li>
              <Link to="/home">Home</Link>
            </li>
            <li>{props.currentpage}</li>
          </ol>
        </div>
      </div>
    </section>
  );
};

export default Header;
