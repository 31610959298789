import "react-tabs/style/react-tabs.css";

import { Tab, TabList, TabPanel, Tabs } from "react-tabs";

import { Container } from "react-bootstrap";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import React from "react";
import Title from "../components/Title";

const Bulkholidays = () => {
  return (
    <div>
      <Navbar />
      <Header
        currentpage="Bulk Holidays Pckages"
        title="Bulk Holidays Pckages"
      />
      <Title title="Bulk Holidays Packages" />
      <Container>
        <Tabs>
          <TabList>
            <Tab>
              <div className="selectedtabs">Concepts</div>
            </Tab>
            <Tab>
              <div className="selectedtabs">
                The bulk holiday packages offered
              </div>
            </Tab>
            <Tab>
              <div className="selectedtabs">
                Hotel Booking Request Procedure for Coupon Holder
              </div>
            </Tab>
          </TabList>

          <TabPanel style={{ fontSize: "14px" }}>
            <h5>CONCEPTS</h5>
            <p>
              We take the pleasure to introduce ourselves as one of the leading
              firms in the travel industry providing impeccable service on time
              every time to our valued customers in the corporate sector as well
              as individuals.
            </p>
            <p>
              Real Holidays, Surat has been providing travel services to its
              clients all over India since the last 10 yrs. Be it air ticketing,
              hotel bookings, bulk holiday packages ( For Corporate Clients),
              Real Holidays provides a One Stop Shop for all your travel and
              holiday requirements.
            </p>
            <p>
              The company is mainly into Corporate Bulk Holiday Bookings
              providing its clients with value addition to their products and
              satisfying the end user by letting them enjoy their holidays at a
              very reasonable price. The said bulk holiday package can be
              combined with any existing company product and in return the
              product gets a value addition which might be in fact much more
              than the price of the original product.
            </p>
            <p>
              The concept has been widely accepted by our reputed clients as
              well as their principles in Surat and other cities across India.
            </p>
            <p>
              At present Real Holidays has entered into collaboration with 45
              hotels in India as well as 27 Hotels in abroad for providing its
              services. More hotels are being added to the list as per the
              company policy.
            </p>
          </TabPanel>
          <TabPanel style={{ fontSize: "14px" }}>
            <h5>THE BULK HOLIDAY PACKAGES OFFERED</h5>
            <ul>
              <li>One Night Holiday Packages.</li>
              <li>Two Night Holiday Packages.</li>
              <li>Three Night Holiday Packages.</li>
            </ul>
            <p>
              <strong>
                The market worth of the said packages is approx. Rs.2000/- to
                Rs.6000/- depending on the nights offered.
              </strong>
            </p>
            <p>
              In addition we can also prepare a customized holiday package as
              per the client's choice.
            </p>
            <p>
              The holiday packages are valid during the off season periods of
              the year
            </p>
            <p>
              We at Real Holidays make sure that each and every customer of ours
              is satisfied with our services as well as the services of the
              respective hotels booked thru us.
            </p>
            <p>
              Real Holidays is committed to providing the best of services on
              time to all its customers with the sole aim of having 100 %
              Customer Satisfaction.
            </p>
          </TabPanel>
          <TabPanel style={{ fontSize: "14px" }}>
            <h5>HOTEL BOOKING REQUEST PROCEDURE FOR COUPON HOLDER</h5>
            <ul>
              <li>
                Coupon Holder has to fill in the booking request form with his
                contact details with postal address, email address, hotel
                reservation dates and two preferences for the hotel location.
              </li>
              <li>
                The booking request should be sent to “Real Holidays”, M-32/33,
                Ashirwad Complex, Bhatar Road, Surat. as per the stipulated time
                period failing which the booking request will not be
                entertained.
              </li>
              <li>
                The booking request form should be sent us by registered post /
                courier to avoid delay in the delivery to our office. Normal
                posts are prone to delay in deliveries.
              </li>
              <li>
                D.D / At Par cheque for Booking charges (if payable) are to be
                made in the name of “Real Holidays” payable at Surat and are to
                be sent along with the booking request.
              </li>
              <li>
                Once the booking request is received by us, we will process the
                same within 7 working days. A date change will have to be given
                by the customer in case of non availability of rooms.
              </li>
              <li>
                Once the booking is confirmed, the customer will get a booking
                voucher from Real Holidays by courier at his / her postal
                address as mentioned in the booking request.
              </li>
              <li>
                In case of cancellation of the hotel booking before 7 days,
                booking charges @ one night will be charged by the respective
                hotels and the balance one night can be used by the customer
                anytime within the coupon validity period.
              </li>
              <li>
                If the booking cancellation is within 7 days of the booking
                date, the entire booking amount will be forfeited by the hotel.
              </li>
            </ul>
          </TabPanel>
        </Tabs>
      </Container>
      <br />
      <Footer />
    </div>
  );
};

export default Bulkholidays;
