import { Column } from "primereact/column";
import { Container } from "react-bootstrap";
import { DataTable } from "primereact/datatable";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import React from "react";
import Title from "../components/Title";

const SeasonData = [
  { month: "January 2023", dates: "1,2,3,4,5,6,7,8" },
  { month: "February 2023", dates: "2,1,4,10,5,6,7,8" },
];
const Offseasonchart = () => {
  return (
    <div>
      <Navbar />
      <Header currentpage="Off Season Chart" title="Off Season Chart" />
      <Title title="Off Season Chart" />
      <Container>
        <DataTable value={SeasonData} style={{ fontSize: "13px" }}>
          <Column
            field="month"
            header="Month - Year"
            sortable={true}
            style={{ width: "100px" }}
          />
          <Column
            field="dates"
            header="Dates"
            sortable={true}
            style={{ width: "100px" }}
          />
        </DataTable>
      </Container>
      <br />
      <Footer />
    </div>
  );
};

export default Offseasonchart;
