import { Col, Container, Row } from "react-bootstrap";

import Footer from "../components/Footer";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import React from "react";
import Title from "../components/Title";

const Contactus = () => {
  return (
    <div>
      <Navbar />
      <Header currentpage="Contact" title="Contact us" />
      <Title title="GET IN TOUCH" />
      <Container>
        <Row xs="auto">
          <Col sm={6}>
            <img
              width="100%"
              src="https://st2.depositphotos.com/1265075/7446/i/600/depositphotos_74468913-stock-photo-contact-us-icons-on-cubes.jpg"
              alt="Contact us"
            />
          </Col>
          <Col sm={6}>
            <h5>ADDRESS:</h5>
            <p>
              M - 32/33, Ashirwad Complex, Bhatar Road,
              <br /> Surat - 395007, Gujarat, India.
            </p>
            <h5>E-MAIL</h5>
            <p>
              realholidays4971@gmail.com <br />
              contact@realholidays.in
            </p>
            <h5>CONTACT NUMBER :</h5>
            <p>
              +91 6354893999 <br /> +91 9426159139
            </p>
          </Col>
        </Row>
      </Container>
      <br />
      <Footer />
    </div>
  );
};

export default Contactus;
