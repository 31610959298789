import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import React from "react";
import Slider from "./Slider";

const Navbar = () => {
  return (
    <div>
      <div>
        <header id="header" className="fixed-top headerbg">
          <div className="container-fluid d-flex align-items-center">
            <h1 className="logo me-auto">
              <a href="/">
                <img src="logo.png" alt="" className="img-fluid" />
              </a>
            </h1>

            <nav id="navbar" className="navbar order-last order-lg-0">
              <ul>
                <li>
                  <Link to="/" className="active">
                    Home
                  </Link>
                </li>

                <li className="dropdown">
                  <Link to="#">
                    <span>About</span> <i className="bi bi-chevron-down"></i>
                  </Link>
                  <ul>
                    <li>
                      <Link to="/about">About Us</Link>
                    </li>
                    <li>
                      <Link to="#">Team</Link>
                    </li>
                    <li>
                      <Link to="#">Testimonials</Link>
                    </li>
                  </ul>
                </li>
                <li className="dropdown">
                  <Link to="#">
                    <span>Our Services</span>{" "}
                    <i className="bi bi-chevron-down"></i>
                  </Link>
                  <ul>
                    <li>
                      <Link to="/bulk-holidays-packages">
                        Bulk Holidays Package
                      </Link>
                    </li>
                    <li>
                      <Link to="#">Hotel Booking And Tour</Link>
                    </li>
                    <li>
                      <Link to="#">Membership</Link>
                    </li>
                    <li>
                      <Link to="#">Real Discount Offers</Link>
                    </li>
                    <li>
                      <Link to="#">Off Season Offers</Link>
                    </li>
                  </ul>
                </li>

                <li>
                  <Link to="/hotel-lists">Hotel List</Link>
                </li>
                <li>
                  <Link to="/off-season-chart">Off Season Chart</Link>
                </li>
                <li>
                  <Link to="/travel-tips">Travel Tips</Link>
                </li>

                <li>
                  <Link to="/contact">Contact</Link>
                </li>
                <li>
                  <Link
                    style={{
                      marginLeft: "10px",
                      paddingRight: "20px",
                      backgroundColor: "#f89d55",
                      color: "white",
                    }}
                    to="/booking-request"
                  >
                    Booking Request
                  </Link>
                </li>
              </ul>
              <i className="bi bi-list mobile-nav-toggle"></i>
            </nav>

            <div className="header-social-links d-flex">
              <a href="#" className="twitter">
                <i className="bu bi-twitter"></i>
              </a>
              <a href="#" className="facebook">
                <i className="bu bi-facebook"></i>
              </a>
              <a href="#" className="instagram">
                <i className="bu bi-instagram"></i>
              </a>
              <a href="#" className="linkedin">
                <i className="bu bi-linkedin"></i>
              </a>
            </div>
          </div>
        </header>
      </div>
    </div>
  );
};

export default Navbar;
